import Image from "next/image";
import DynamicImage from "@/components/DynamicImage";
import { AnimatedButton } from "./common/AnimatedButton";
const Hero = () => {
  return (
    <div className="bg-[url('https://scrm-zhuque.oss-cn-shenzhen.aliyuncs.com/staticIcon/home/topBg.png')] w-full bg-cover relative">
      <div className="pl-[182px] pt-[217px] pb-[192px]">
        <DynamicImage
          src="https://scrm-zhuque.oss-cn-shenzhen.aliyuncs.com/staticIcon/home/topText.png"
          width={443}
          height={147}
          alt="像好友一样服务客户"
        ></DynamicImage>
        <div className="mt-[42px] flex items-center gap-4">
          <AnimatedButton className="w-[140px] h-[52px] flex items-center justify-center bg-[#FF6700] text-base text-[#FFFFFF] px-4 py-2 rounded-sm hover:bg-orange-600">
            免费试用
          </AnimatedButton>
          <AnimatedButton className="border bg-white w-[104px] h-[52px] text-[#FF6700] text-base flex items-center justify-center border-[#FF6700] hover:text-orange-600 rounded-sm">
            立即咨询
          </AnimatedButton>
        </div>
      </div>
    </div>
  );
};

export default Hero;
