'use client'
import { usePathname } from 'next/navigation';
import { useEffect, useState } from 'react';
import { motion } from 'framer-motion';

export const RouteProgress = () => {
  const pathname = usePathname();
  // const searchParams = useSearchParams();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    const timer = setTimeout(() => setIsLoading(false), 500);
    return () => clearTimeout(timer);
  }, [pathname]);

  if (!isLoading) return null;

  return (
    <motion.div
      className="fixed top-0 left-0 right-0 h-1 bg-themeColor z-50"
      initial={{ scaleX: 0, transformOrigin: '0%' }}
      animate={{ scaleX: 1 }}
      transition={{ duration: 0.5 }}
    />
  );
};
