import { motion } from "framer-motion";
import {
  MessageSquare,
  Users,
  Shield,
  Zap,
  BarChart,
  Globe,
} from "lucide-react";
import Image from "next/image";
import DynamicImage from "@/components/DynamicImage";
import { useState } from "react";

const contacts = [
  {
    icon: "https://scrm-zhuque.oss-cn-shenzhen.aliyuncs.com/staticIcon/home/contact/icon1.png",
    title: "业务增长赋能顾问",
    description: "定位运营问题，最全维度的诊断咨询，指定效能提升方案",
  },
  {
    icon: "https://scrm-zhuque.oss-cn-shenzhen.aliyuncs.com/staticIcon/home/contact/icon2.png",
    title: "技术支持",
    description:
      "强大的技术保障，高效响应客户需求， 实时在线解答，降低企业的运营成本",
  },
  {
    icon: "https://scrm-zhuque.oss-cn-shenzhen.aliyuncs.com/staticIcon/home/contact/icon3.png",
    title: "企业数据安全",
    description:
      "专属数据密钥、新型加密技术高级别加密协议、标准API接口以及私有化部署服务从源头保护企业数据安全",
  },
];

const Contact = () => {
  const [active, setActive] = useState(0);
  return (
    <section
      id="features"
      className="bg-[url('https://scrm-zhuque.oss-cn-shenzhen.aliyuncs.com/staticIcon/home/contact/bg.png')] w-full bg-cover"
    >
      <div className="container mx-auto px-4 pb-16">
        <motion.div
          className="text-center mt-12"
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          transition={{ duration: 0.6 }}
        >
          <h2 className="text-4xl md:text-4xl font-bold text-black/85">
            选择微友智汇，像好友一样服务客户<br /> 让您的私域运营更省心
          </h2>
        </motion.div>

        <div className="flex justify-center gap-8 mt-[68px]">
          {contacts.map((item, index) => (
            <motion.div
              key={index}
              className={`w-[348px] min-h-[152px] px-4 pt-[26px] pb-4 relative bg-white rounded-xl shadow-lg hover:shadow-xl transition-shadow bg-cover`}
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true }}
              transition={{ duration: 0.6, delay: index * 0.1 }}
              onClick={() => setActive(index)}
            >
              <DynamicImage
                src={item.icon}
                alt={item.title}
                width={72}
                height={102}
                className="absolute -top-6 right-6"
              ></DynamicImage>
              <p className="text-xl font-bold text-black/85">{item.title}</p>
              <p className="text-sm text-black/85 mt-4">{item.description}</p>
            </motion.div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Contact;
