import { motion } from "framer-motion";
import {
  MessageSquare,
  Users,
  Shield,
  Zap,
  BarChart,
  Globe,
} from "lucide-react";
import Image from "next/image";
import DynamicImage from "@/components/DynamicImage";
import { useState } from "react";
import { AnimatedButton } from "./common/AnimatedButton";

const Description = () => {
  return (
    <section
      id="features"
      className="bg-[url('https://scrm-zhuque.oss-cn-shenzhen.aliyuncs.com/staticIcon/home/description/bg.png')] w-full bg-cover"
    >
      <div className="container mx-auto px-4 pb-16">
        <motion.div
          className="text-center mt-12"
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          transition={{ duration: 0.6 }}
        >
          <h2 className="text-4xl md:text-4xl font-bold text-black/85">
            公司简介
          </h2>
        </motion.div>
        <div className="flex mt-12 justify-center">
          <DynamicImage
            src="https://scrm-zhuque.oss-cn-shenzhen.aliyuncs.com/staticIcon/home/description/left.png"
            className="shadow-sm border-2 border-white/50"
            width={466}
            height={287}
            alt="公司简介"
          ></DynamicImage>
          <div className="bg-[url('https://scrm-zhuque.oss-cn-shenzhen.aliyuncs.com/staticIcon/home/description/textBg.png')] w-[610px] h-[227px] bg-cover p-6 relative mt-[30px]">
            微友智汇SCRM是深圳市朱雀软件科技有限公司旗下的服务营销一体化平台，微友智汇SCRM以客户为核心，通过社交媒体平台连接企业与客户。通过整合多渠道客户数据，实现自动化管理流程，促进企业在社交媒体平台上与客户高效互动，同时为企业提供个性化服务与精准营销，帮助企业实现全渠道客户服务，精细化客户关系管理，优化客户体验，帮助机构找到全链路可落地的闭环增长策略与数字化解决方案...
            <AnimatedButton className="absolute w-[150px] h-[54px] rounded-s-sm shadow-black/5 border border-themeColor text-lg text-white font-medium -bottom-3.5 right-6 bg-themeColor flex justify-center items-center">
              了解更多
            </AnimatedButton>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Description;
