import { type ReactNode } from "react";

interface JsonLdProps {
  type?: "software" | "organization" | "website" | "product";
  data?: Record<string, any>;
}

export default function JsonLd({ type = "software", data }: JsonLdProps) {
  const baseJsonLd = {
    "@context": "https://schema.org",
  };

  const softwareJsonLd = {
    ...baseJsonLd,
    "@type": "SoftwareApplication",
    name: "微友智汇SaaS平台",
    applicationCategory: "BusinessApplication",
    operatingSystem: "Web",
    description: "打造一站式企业服务平台，整合办公、沟通、协作，让企业运营更高效",
    offers: {
      "@type": "AggregateOffer",
      priceCurrency: "CNY",
      lowPrice: "299",
      highPrice: "699",
    },
    aggregateRating: {
      "@type": "AggregateRating",
      ratingValue: "4.8",
      ratingCount: "1250",
    },
    featureList: [
      "智能消息管理",
      "团队协作",
      "安全可靠",
      "自动化工作流",
      "数据分析",
      "全球化支持",
    ],
  };

  const organizationJsonLd = {
    ...baseJsonLd,
    "@type": "Organization",
    name: "微友智汇SaaS",
    url: "https://wechatwork-saas.com",
    logo: "https://scrm-zhuque.oss-cn-shenzhen.aliyuncs.com/staticIcon/home/logo.png",
    sameAs: [
      "https://weibo.com/微友智汇",
      "https://twitter.com/微友智汇",
    ],
    contactPoint: {
      "@type": "ContactPoint",
      telephone: "+86-XXX-XXXX-XXXX",
      contactType: "customer service",
      areaServed: "CN",
      availableLanguage: ["Chinese", "English"],
    },
  };

  const websiteJsonLd = {
    ...baseJsonLd,
    "@type": "WebSite",
    name: "微友智汇SaaS平台",
    url: "https://wechatwork-saas.com",
    potentialAction: {
      "@type": "SearchAction",
      target: {
        "@type": "EntryPoint",
        urlTemplate: "https://wechatwork-saas.com/search?q={search_term_string}",
      },
      "query-input": "required name=search_term_string",
    },
  };

  const productJsonLd = {
    ...baseJsonLd,
    "@type": "Product",
    name: "微友智汇SaaS平台",
    image: "https://scrm-zhuque.oss-cn-shenzhen.aliyuncs.com/staticIcon/home/logo.png",
    description: "打造一站式企业服务平台，整合办公、沟通、协作，让企业运营更高效",
    brand: {
      "@type": "Brand",
      name: "微友智汇",
    },
    offers: {
      "@type": "AggregateOffer",
      priceCurrency: "CNY",
      lowPrice: "299",
      highPrice: "699",
      availability: "https://schema.org/InStock",
    },
  };

  const getJsonLd = () => {
    switch (type) {
      case "organization":
        return { ...organizationJsonLd, ...data };
      case "website":
        return { ...websiteJsonLd, ...data };
      case "product":
        return { ...productJsonLd, ...data };
      default:
        return { ...softwareJsonLd, ...data };
    }
  };

  return (
    <script
      type="application/ld+json"
      dangerouslySetInnerHTML={{ __html: JSON.stringify(getJsonLd()) }}
    />
  );
}
