'use client'

import { AnimatedContainer, AnimatedItem } from '@/components/common/AnimatedContainer'
import { motion } from 'framer-motion'

export default function NotFound() {
  return (
    <AnimatedContainer className="flex min-h-screen flex-col items-center justify-center">
      <AnimatedItem>
        <h2 className="text-4xl font-bold mb-4">404 - 页面未找到</h2>
      </AnimatedItem>

      <AnimatedItem>
        <p className="text-gray-600 mb-8">抱歉，您访问的页面不存在</p>
      </AnimatedItem>

      <AnimatedItem>
        <motion.a
          href="/"
          className="px-6 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700 transition-colors"
          whileHover={{ scale: 1.05 }}
          whileTap={{ scale: 0.95 }}
          transition={{ type: "spring", stiffness: 400, damping: 17 }}
        >
          返回首页
        </motion.a>
      </AnimatedItem>
    </AnimatedContainer>
  )
}
