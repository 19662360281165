import DynamicImage from "./DynamicImage";
import { useState } from "react";

const Qrcode = () => {
  const [isQrCode, setIsQrCode] = useState(false);
  return (
    <div
      className="rounded-cycle w-[86px] h-[86px] bg-white shadow-sm fixed z-50 flex flex-col justify-center items-center gap-y-1 top-[800px] right-8"
      onMouseEnter={() => setIsQrCode(true)}
      onMouseLeave={() => setIsQrCode(false)}
    >
      {isQrCode && (
        <div className="w-[184px] h-[236px] p-2 flex flex-wrap fixed top-[550px] right-6 rounded-md bg-white">
          <DynamicImage
            src="https://scrm-zhuque.oss-cn-shenzhen.aliyuncs.com/staticIcon/home/qrcode.png"
            width={168}
            height={168}
            alt="微友智汇二维码"
          ></DynamicImage>
          <p className="mt-2 text-black/85 text-sm font-medium w-full text-center">
            立即扫码
          </p>
          <p className="mt-2 text-black/85 text-sm font-medium w-full text-center">
            获取私域运营增长方案
          </p>
        </div>
      )}
      <DynamicImage
        src="https://scrm-zhuque.oss-cn-shenzhen.aliyuncs.com/staticIcon/home/kefu.png"
        width={30}
        height={30}
      ></DynamicImage>
      <span className="text-black/85 text-sm w-full text-center">联系我们</span>
    </div>
  );
};

export default Qrcode;
