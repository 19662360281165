"use client";
import { NextSeo } from "next-seo";
import Header from "@/components/Header";
import Hero from "@/components/Hero";
import Features from "@/components/Features";
import Pains from "@/components/Pains";
import Solutions from "@/components/Solutions";
import Manages from "@/components/Manages";
import Contact from "@/components/Contact";
import Description from "@/components/Description";
import Bottom from "@/components/Bottom";
import Footer from "@/components/Footer";
import JsonLd from "@/components/JsonLd";
import QrCode from "@/components/Qrcode";
// import { generateMetadata } from "./seo-config";

// export const metadata = generateMetadata(
//   "微友智汇SaaS平台 - 智能化企业协作解决方案",
//   "打造一站式企业服务平台，整合办公、沟通、协作，让企业运营更高效。提供智能消息管理、团队协作、数据分析等企业级功能。"
// );

export default function Home() {
  return (
    <>
      <JsonLd type="software" />
      <JsonLd type="organization" />
      <JsonLd type="website" />
      <JsonLd type="product" />
      <main className="min-h-screen min-w-[1280px] flex flex-wrap ">
        <Header />
        <QrCode />
        <Hero />
        <Features />
        <Pains />
        <Solutions />
        <Manages />
        <Contact />
        <Description />
        <Bottom />
        <Footer />
      </main>
    </>
  );
}
