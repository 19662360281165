import(/* webpackMode: "eager" */ "D:\\produce\\hbxt-scrm-home\\src\\app\\globals.css");
;
import(/* webpackMode: "eager" */ "D:\\produce\\hbxt-scrm-home\\node_modules\\next\\font\\google\\target.css?{\"path\":\"src\\\\app\\\\fonts.ts\",\"import\":\"Noto_Sans_SC\",\"arguments\":[{\"subsets\":[\"latin\"],\"weight\":[\"100\",\"300\",\"400\",\"500\",\"700\",\"900\"],\"display\":\"swap\",\"variable\":\"--font-noto-sans-sc\"}],\"variableName\":\"notoSansSC\"}");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "D:\\produce\\hbxt-scrm-home\\src\\components\\common\\ErrorBoundary.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["LoadingProvider"] */ "D:\\produce\\hbxt-scrm-home\\src\\components\\common\\LoadingProvider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["RouteProgress"] */ "D:\\produce\\hbxt-scrm-home\\src\\components\\common\\RouteProgress.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["ToastProvider"] */ "D:\\produce\\hbxt-scrm-home\\src\\components\\common\\ToastProvider.tsx");
